import React, { useEffect } from "react"
import { handleLoginResponse } from "../../utilities/userManager"
import ReactLoading from "react-loading"
import Helmet from "react-helmet"
import "../../styles/global.scss"
import "../../styles/login.scss"
import LoginBackground from "../../components/login/login-background.js"
import telstraLogo from "../../images/telstra-logo-magenta.png"
import { ToastContainer, toast } from "react-toastify"

const Login = () => {
  useEffect(() => {
    handleLoginResponse()
  })

  return (
    <div className="login-root">
      <LoginBackground classToApply="background-image login-background" />
      <Helmet
        title="Login - MNaaS Portal"
        meta={[
          { name: "description", content: "Login page for the MNaaS Portal" },
        ]}
      >
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <html lang="en" />
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        className="toast-container"
        toastClassName="toast-info"
        bodyClassName="toast-body"
      />
      <div className="login-box-container">
        <div className="login-box-content">
          <h1>MNaaS Portal</h1>
          <img alt="Telstra Logo" src={telstraLogo} />
          <ReactLoading
            style={{
              marginTop: "0",
              marginLeft: "auto",
              marginRight: "auto",
              width: "50px",
              height: "50px",
              position: "absolute",
            }}
            type={"spokes"}
            color={"#444444"}
          />
        </div>
      </div>
    </div>
  )
}

export default Login
